'use strict'

routes.order_confirmation = function(id, action) {
    
    mount('loading');
         recipeService.fetchOrderConfirmRecommendedRecipes()
        .then(function(recipes) {
          var recommendedRecipies={}
             for (var key in recipes ){
               recommendedRecipies[key]=recipes[key];
             }
             
          
            mount('order_confirmation', { recipes: recommendedRecipies});
         });
}
